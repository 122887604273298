<!-- HEADER start -->
<nav *ngIf="!(isMobileView$ | async)" class="header sticky-top">
    <div class="header-left" [ngClass]="isToggled() ? 'md-move-right' : ''">
        <div class="header-menu-button" [class.toggle]="!isToggled()" (click)="toggleSidebar()">
            <div class="hoverable-button"></div>
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
        </div>
        <a class="header-logo" routerLink="/orders">
            <img [src]="logoPath" width="auto" height="auto" alt="" style="max-height: 60px;">
        </a>
    </div>

    <app-headmenu></app-headmenu>

    <div class="header-right">
        <div class="btn-group header-dropdown d-none d-md-block" dropdown>
            <button dropdownToggle type="button" class="btn dropdown-toggle btn-custom" aria-controls="dropdown-basic">
                <img class="users-img" src="/assets/gfx/icons/person-24px-white.svg" width="auto" height="auto" alt="">
                <span class="d-inline" style="padding-right:48px;">
                  <span class="users-name">{{ currentUserCompany?.name }}</span>
                  <span class="users-role">{{ currentUser?.forename }}&nbsp;{{ currentUser?.surname }}</span>
                </span>
                <span class="caret">
                  <span></span>
                  <span></span>
                </span>
            </button>
            <ul *dropdownMenu class="dropdown-menu">
                <li role="menuitem">
                    <a class="dropdown-item" [routerLink]="">
                        <img class="users-img"
                             src="/assets/gfx/icons/person-24px-white.svg"
                             aria-hidden="true"
                             width="auto"
                             height="auto"
                             alt="">
                        Minu profiil
                    </a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" (click)="selectRole()">
                        <img class="users-img" src="/assets/gfx/icons/swap_horiz-24px-white.svg" width="auto"
                             height="auto" alt="">Vaheta
                        rolli</a>
                </li>
                <li *ngIf="emailButtonEnabled" role="menuitem">
                    <a class="dropdown-item" (click)="configureEmailSettings()">
                        <img class="users-img" src="/assets/gfx/icons/header_envelope.svg" width="auto" height="auto"
                             alt="">Seadista
                        e-posti teavitused</a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" (click)="logout()">
                        <img class="users-img" src="/assets/gfx/icons/logout-24px-white.svg" width="auto" height="auto"
                             alt="">Logi
                        välja</a>
                </li>
            </ul>
        </div>

        <div class="btn-group header-dropdown d-flex d-md-none" dropdown placement="bottom right">
            <button dropdownToggle type="button" class="btn dropdown-toggle btn-custom" aria-controls="dropdown-basic">
                <img class="users-img" src="/assets/gfx/icons/person-24px-white.svg" width="auto" height="auto" alt="">
                <span class="d-inline">
          <span class="users-name">{{ currentUserCompany?.name }}</span>
        </span>
                <span class="caret">
          <span></span>
          <span></span>
        </span>
            </button>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                <li role="menuitem">
                    <a class="dropdown-item" [routerLink]="">
                        <img class="users-img" src="/assets/gfx/icons/person-24px-white.svg" width="auto" height="auto"
                            aria-hidden="true" alt="">Minu
                        profiil</a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" (click)="selectRole()">
                        <img class="users-img" src="/assets/gfx/icons/swap_horiz-24px-white.svg" width="auto"
                             height="auto" alt="">Vaheta
                        rolli</a>
                </li>
                <li *ngIf="emailButtonEnabled" role="menuitem">
                    <a class="dropdown-item" (click)="configureEmailSettings()">
                        <img class="users-img" src="/assets/gfx/icons/header_envelope.svg" width="auto" height="auto"
                             alt="">Seadista
                        e-posti teavitused</a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" (click)="logout()">
                        <img class="users-img" src="/assets/gfx/icons/logout-24px-white.svg" width="auto" height="auto"
                             alt="">Logi
                        välja
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>

<!-- HEADER end -->

<nav *ngIf="isMobileView$ | async" class="header sticky-top">

    <div class="header-left" [ngClass]="isToggled() ? 'md-move-right' : ''">
        <div class="header-menu-button" [class.toggle]="!isToggled()" (click)="toggleSidebar()">
            <div *ngIf="!isToggled()">
                <div class="hoverable-button"></div>
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
            </div>
            <span *ngIf="isToggled()" class="icon icon_close-white icon-big close-toggle"></span>
        </div>

        <!-- LEFT MOBILE MENU START-->
        <div class="d-flex mobile-menu-left" [ngClass]="isToggled() ? 'active' : ''">
            <ul>
                <li class="menu-item" (click)="toggleSubMenuItem(activeMenuEnum.WORKS)">
                    <a [ngClass]="activeMenu === activeMenuEnum.WORKS ? 'active-menu' : ''">Töölaud
                        <span class="dropdown-toggle custom-caret"
                              [ngClass]="activeMenu === 'WORKS' ? 'active' : ''"></span>
                    </a>

                    <ul class="sub-menu" [ngClass]="activeMenu === 'WORKS' ? 'active' : ''">
                        <li>
                            <a routerLink="/orders" routerLinkActive="active">
                                Töötaotlused
                            </a>
                        </li>
                        <li class="nav-item" *ngxPermissionsOnly="'order.warranty.manage'">
                            <a routerLink="/warranty" routerLinkActive="active">
                                Garantii
                            </a>
                        </li>
                        <li *ngxPermissionsOnly="[
                          'inspection.access.all',
                          'inspection.access.property-inspections',
                          'inspection.access.regional-property-inspections',
                          'inspection.access.client-own-building-inspections',
                          'inspection.access.company-inspections',
                          'th.access.records-north',
                          'th.access.records-south',
                          'th.access.records-east',
                          'th.access.records-west',
                          'hk.access.records-north',
                          'hk.access.records-south',
                          'hk.access.records-east',
                          'hk.access.records-west',
                          'tt.access.records-north',
                          'tt.access.records-south',
                          'tt.access.records-east',
                          'tt.access.records-west',
                          'inspection.access.client-inspections',
                          'inspection.access.same-governance-area-inspections'
                        ]">
                            <a routerLink="/inspections" routerLinkActive="active">
                                Ülevaatused
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/messages-user" routerLinkActive="active">
                                <span>Teavitused</span>
                                <span class="icon bell pt-1"></span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngxPermissionsOnly="['appliance-rule.access']"
                    class="menu-item"
                    (click)="toggleSubMenuItem(activeMenuEnum.MAINTENANCE)">
                    <a [ngClass]="activeMenu === activeMenuEnum.MAINTENANCE ? 'active-menu' : ''">Hooldustööd
                        <span class="dropdown-toggle custom-caret"
                              [ngClass]="activeMenu === activeMenuEnum.MAINTENANCE ? 'active' : ''"></span>
                    </a>

                    <ul routerLinkActive="parent_visible" *ngxPermissionsOnly="['appliance-rule.access']"
                        class="sub-menu" [ngClass]="activeMenu === activeMenuEnum.MAINTENANCE ? 'active' : ''">
                        <li>
                            <a routerLink="/appliance/schedule/range" routerLinkActive="active" [queryParams]="nextMonthParams$ | async">
                                Hooldusgraafik
                            </a>
                            <a routerLink="/appliance/schedule"
                               routerLinkActive="active"
                               class="d-none">
                            </a>
                            <a routerLink="/appliance/schedule/calendar"
                               routerLinkActive="active"
                               class="d-none">
                            </a>
                        </li>
                        <li>
                            <a routerLink="/appliance/plan/list" routerLinkActive="active">
                                Hoolduskava
                            </a>
                            <a routerLink="/appliance/plan"
                               routerLinkActive="active"
                               class="d-none">
                            </a>
                            <a routerLink="/appliance/plan/calendar"
                               routerLinkActive="active"
                               class="d-none">
                            </a>
                        </li>
                        <li>
                            <a routerLink="/appliance/orders" routerLinkActive="active">
                                Hoolduspäevik
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngxPermissionsOnly="'act.access'" class="menu-item" (click)="toggleSubMenuItem(activeMenuEnum.ACTS)">
                    <a [ngClass]="activeMenu === activeMenuEnum.ACTS ? 'active-menu' : ''"> Akteerimine
                        <span class="dropdown-toggle custom-caret"
                              [ngClass]="activeMenu === activeMenuEnum.ACTS ? 'active' : ''"></span>
                    </a>

                    <ul class="sub-menu" [ngClass]="activeMenu === activeMenuEnum.ACTS ? 'active' : ''">
                        <li *ngxPermissionsOnly="['act.access']">
                            <a routerLink="/acts/regular" routerLinkActive="active">
                                Aktid
                            </a>
                        </li>
                        <li *ngxPermissionsOnly="['act.access.all-quickacts', 'act.access.property-manager-quickacts']">
                            <a routerLink="/acts/quick">
                                Kiiraktid
                            </a>
                        </li>
                    </ul>
                </li>
        <li *ngxPermissionsOnly="['repair.access.governanceArea', 'repair.access.company']"
                    class="menu-item">
                    <a routerLinkActive="active-menu"
                       routerLink="/repairs"
                       (click)="toggleSubMenuItem(undefined)">
                        Remondikoond
                    </a>
                </li>        <li class="menu-item"
                    (click)="toggleSubMenuItem(activeMenuEnum.ADMINISTRATION)">
                    <a [ngClass]="activeMenu === activeMenuEnum.ADMINISTRATION ? 'active-menu' : ''">Haldus
                        <span class="dropdown-toggle custom-caret"
                              [ngClass]="activeMenu === activeMenuEnum.ADMINISTRATION ? 'active' : ''"></span>
                    </a>

                    <ul class="sub-menu" [ngClass]="activeMenu === activeMenuEnum.ADMINISTRATION ? 'active' : ''">
                        <li *ngxPermissionsOnly="[
                            'maintenance-contract.access.all-contracts',
                            'maintenance-contract.access.company-contracts',
                            'maintenance-contract.access.property-contracts',
                            'maintenance-contract.access.regional-property-contracts',
                            'access.records-contractor-project-manager',
                            'access.records-contractor-dispatcher',
                            'access.records-contractor-works-manager',
                            'access.records-rkik',
                            'access.records-prison-tallinn',
                            'access.records-prison-tartu',
                            'access.records-prison-viru',
                            'access.records-all-except-prison',
                            'th.access.records-north',
                            'th.access.records-south',
                            'th.access.records-east',
                            'th.access.records-west',
                            'hk.access.records-north',
                            'hk.access.records-south',
                            'hk.access.records-east',
                            'hk.access.records-west',
                            'tt.access.records-north',
                            'tt.access.records-south',
                            'tt.access.records-east',
                            'tt.access.records-west'
                          ]">
                            <a routerLink="/contracts" routerLinkActive="active">
                                Korrashoiuteenuste lepingud
                            </a>
                        </li>
                        <li class="nav-item"
                            *ngxPermissionsOnly="['construction-contract.access']">
                            <a routerLink="/construction-contracts" routerLinkActive="active">
                                Ehituslepingud
                            </a>
                        </li>
                        <li *ngxPermissionsOnly="['building-user.manage']">
                            <a routerLink="/buildings" routerLinkActive="active">
                                Hooned
                            </a>
                        </li>
                        <li class="nav-item"
                            routerLinkActive="active"
                            *ngxPermissionsOnly="['order-report.access.all']">
                            <a class="nav-link" routerLink="/reports">
                                Aruanne
                            </a>
                        </li>
                        <li class="nav-item" *ngxPermissionsOnly="['messages.access.all']">
                            <a routerLink="/messages" routerLinkActive="active">
                                Teavitused
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

    </div>
    <!-- RIGHT MOBILE MENU START-->
    <div class="d-flex justify-content-end">
        <a class="header-logo" routerLink="/orders">
            <div class="d-flex flex-column align-items-end">
                <img [src]="logoPath" width="100%" height="auto" alt="" style="max-height: 16px;">
                Works
            </div>
        </a>
    </div>
    <div class="mobile-header btn-group header-dropdown d-flex" dropdown placement="bottom right"
         #dropdown="bs-dropdown">
        <button dropdownToggle type="button" class="btn btn-custom" aria-controls="dropdown-basic"
                (click)="isToggled() ? toggleSidebar() : ''">
            <img src="/assets/gfx/icons/person-24px-white.svg" width="auto" height="auto" alt="">
            </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right mobile-header--dropdown">
                <li role="menuitem">
                    <a class="dropdown-item dropdown--toggle" [routerLink]="">
                        <div class="d-flex">
                            <img class="users-img" src="/assets/gfx/icons/person-24px-white.svg" width="auto"
                                 height="auto" alt="" aria-hidden="true">
                            <span class="user-details">
                <span class="d-flex flex-column">
                  <span class="text-wrap">{{ currentUserCompany?.name }}</span>
                  <span class="users-role">{{ currentUser?.forename }}&nbsp;{{ currentUser?.surname }}</span>
                </span>
                <span>
                  <button type="button" class="btn btn-close-sidenav">
                    <span class="icon icon_close-white icon-big"></span>
                  </button>
                </span>
              </span>
                        </div>
                    </a>
                </li>
                <li role="menuitem" class="role-change" (click)="$event.stopPropagation()">
                    <div class="role-change-container"
                         (click)="toggleUserCompanies()">
                        <div class="d-flex justify-content-between align-items-center w-100"
                             [class.active-menu]="!!this.showUserCompanies">
                            <div>
                                <a class="dropdown-item d-flex role-change-item">
                                    <img class="users-img" src="/assets/gfx/icons/swap_horiz-24px-white.svg"
                                         width="auto" height="auto"
                                         alt="">
                                    Vaheta rolli
                                </a>
                            </div>
                            <div>
                                <span class="dropdown-toggle custom-caret d-flex"
                                      [ngClass]="showUserCompanies  ? 'active' : ''"></span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="showUserCompanies">
                        <div role="menuitem" class="role role-selection"
                             *ngFor="let userCompany of currentUser?.companies; let i = index"
                             [class.current]="userCompany?.id == currentUser?.userCompany?.id"
                             (click)="selectUserCompany(userCompany)">
                            <div class="col-12 d-flex align-items-center justify-content-start">
                                <img class="users-img" src="/assets/gfx/icons/swap_horiz-24px-white.svg" width="auto"
                                     height="auto"
                                     alt="">
                                <button class="btn text-white">
                                    {{ userCompany.name }}
                                </button>
                            </div>
                        </div>

                        <ng-container *ngFor="let substitution of currentUser.substitutions">
                            <div class="role role-selection"
                                 *ngFor="let userCompany of substitution.actingAsUser.companies; let i = index"
                                 [class.current]="userCompany?.id == currentUser?.userCompany?.id">
                                <div class="col-12 d-flex align-items-center justify-content-start">
                                    <img class="users-img" src="/assets/gfx/icons/swap_horiz-24px-white.svg"
                                         width="auto" height="auto"
                                         alt="">
                                    <button (click)="selectUserCompany(userCompany)"
                                            class="btn text-white">{{ userCompany.name }}
                                        ({{ substitution.actingAsUser.surname }} {{ substitution.actingAsUser.forename }})
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" (click)="logout()">
                        <img class="users-img" src="/assets/gfx/icons/logout-24px-white.svg" width="auto" height="auto"
                             alt="">Logi
                        välja
                    </a>
                </li>
            </ul>

    </div>
</nav>
