import {Component, OnDestroy, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';
import {ConfigService} from '@app/config';
import {AuthService} from '../../services/auth.service';
import {UserNotificationPermissionService} from '../../services/user-notification-permission.service';
import {UserLoginDto} from '../../resource-dto/user-login';
import {RepresentativeModalComponent} from '../representative-modal/representative-modal.component';
import {UserNotificationPermissionsModalComponent} from '../user-notification-permissions-modal/user-notification-permissions-modal.component';
import {ResizeService} from '../../services/resize.service';
import {ActiveMenu} from '../../constants/active-menu';
import {NavbarService} from '../navbar/navbar.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [BsDropdownDirective]
})
export class HeaderComponent implements OnDestroy {

    @ViewChild('dropdown')
    public dropdown: BsDropdownDirective;

    private readonly ngDestroy = new Subject<void>();
    private readonly pushRightClass = 'sidenav-open';
    public readonly logoPath = this.config.get('logo');
    public readonly isMobileView$ = this.resizeService.isMobileView$;
    public readonly nextMonthParams$ = this.navbarService.nextMonthParams$;

    public currentUser: UserLoginDto.User = null;
    public currentUserCompany: UserLoginDto.UserCompany = null;
    public emailButtonEnabled = false;
    public showUserCompanies = false;
    public activeMenu?: ActiveMenu;
    public activeMenuEnum = ActiveMenu;

    constructor(
        router: Router,
        private config: ConfigService,
        private authService: AuthService,
        private userNotificationPermissionService: UserNotificationPermissionService,
        private modalService: BsModalService,
        private resizeService: ResizeService,
        private navbarService: NavbarService
    ) {
        this.authService.user$.pipe(takeUntil(this.ngDestroy)).subscribe(user => this.currentUser = user);
        this.authService.userCompany$.subscribe(_ => this.currentUserCompany = this.authService.getCurrentUserCompany());

        this.userNotificationPermissionService.userNotificationPermissions$.subscribe((settings) => {
            this.emailButtonEnabled = settings?.roles?.findIndex(value => value.permissions.length > 0) > -1;
        });

        router.events.pipe(takeUntil(this.ngDestroy)).subscribe((val) => {
            if (val instanceof NavigationEnd && this.isToggled()) {
                this.toggleSidebar();
            }
        });
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public toggleSidebar(): void {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    public isToggled(): boolean {
        const dom: Element = document.querySelector('body');

        return dom.classList.contains(this.pushRightClass);
    }

    public selectRole(): void {
        this.modalService.show(RepresentativeModalComponent);
    }

    public toggleUserCompanies() {
        return this.showUserCompanies = !this.showUserCompanies;
    }

    public configureEmailSettings(): void {
        this.modalService.show(UserNotificationPermissionsModalComponent);
    }

    public logout(): void {
        this.authService.redirectToCasLogout();
    }

    public selectUserCompany(userCompany: UserLoginDto.UserCompany): void {
        this.authService.doUserCompanyLogin(userCompany);
        this.dropdown.hide();
    }

    public toggleSubMenuItem(menu: ActiveMenu | undefined): void {
        this.activeMenu = menu !== this.activeMenu ? menu : undefined;
    }
}
