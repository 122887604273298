import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {MatomoInjector} from 'ngx-matomo';
import * as moment from 'moment';
import {ConfigService} from '@app/config/config.service';
import {AuthService} from '@app/core/services/auth.service';
import {LoggerService} from '@app/core/services/logger.service';
import {ClassifierService} from '@app/core/services/classifier.service';
import {NavbarService} from '@app/core/components/navbar/navbar.service';
import {RepresentativeModalComponent} from '@app/core/components/representative-modal/representative-modal.component';
import {SessionTimeoutService} from '@app/core/services/session-timeout.service';
import {ApplianceTypeService} from '@app/core/services/appliance-type.service';
import {AppNotificationResource} from '@app/core/resource/app-notification.resource';
import {UserLoginDto} from '@app/core/resource-dto/user-login';
import {ResizeService} from '@app/core/services/resize.service';
import {DOCUMENT} from '@angular/common';
import User = UserLoginDto.User;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    @ViewChild('header', {read: ElementRef})
    public header: ElementRef;

    @ViewChild('navbar', {read: ElementRef})
    public navbar: ElementRef;

    public readonly footerComponent$ = this.navbarService.footerTemplate$;
    private readonly ngDestroy = new Subject<void>();
    public readonly isMobileView$ = this.resizeService.isMobileView$;

    public isUserLoggedIn = false;
    private currentUser: User = null;

    public constructor(
        sessionTimeoutService: SessionTimeoutService,
        classifierService: ClassifierService,
        applianceTypeService: ApplianceTypeService,
        localeService: BsLocaleService,
        private config: ConfigService,
        private authService: AuthService,
        private appNotificationResource: AppNotificationResource,
        private matomoInjector: MatomoInjector,
        private logger: LoggerService,
        private modalService: BsModalService,
        private toastr: ToastrService,
        private navbarService: NavbarService,
        private resizeService: ResizeService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.validateCompany();
        this.initMatomo();
        classifierService.init();
        sessionTimeoutService.init();
        applianceTypeService.init();
        localeService.use('et');
        document.documentElement.lang = 'et';
    }

    public ngOnInit(): void {
        this.authService.user$.subscribe((user: User) => {
            this.currentUser = user;
            this.isUserLoggedIn = !!this.currentUser;
            if (!this.authService.getCurrentUserCompany()) {
                if (this.currentUser.companies.length === 1) {
                    if (this.currentUser.companies[0]) {
                        this.authService.doUserCompanyLogin(this.currentUser.companies[0]);
                    }
                } else {
                    this.modalService.show(RepresentativeModalComponent);
                }
            }
        });

        if (this.isUserLoggedIn) {
            this.appNotificationResource.get().then((notification: any) => {
                if (notification.appNotificationEnable) {
                    let show = true;
                    const dismissInfo: string = localStorage.getItem('appNotifcationDismissed');
                    if (dismissInfo && !moment(moment(dismissInfo)).isBefore(notification.appNotificationUpdated)) {
                        show = false;
                    }

                    if (show) {
                        this.toastr.error(notification.appNotificationDetail, 'Teade!', {
                            disableTimeOut: true,
                            closeButton: true
                        }).onHidden.subscribe(() => {
                            localStorage.setItem('appNotifcationDismissed', notification.appNotificationUpdated);
                        });
                    }
                }
            }, _ => {
                this.logger.debug('No app notifications found');
            });
        }
    }

    private initMatomo(): void {
        const matomoCfg = this.config.get('matomo');
        if (!matomoCfg) return;

        this.matomoInjector.init(matomoCfg.url, matomoCfg.siteId);
    }

    private validateCompany(): void {
        if (!this.authService.invalidCompany) return;

        this.toastr.error('Olete suundunud lehele vale esindusõiguse lingiga. Valige esindusõigus.', 'Vale esindusõigusega link!');
        this.authService.invalidCompany = false;
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }
}
